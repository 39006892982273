import React, { Component } from 'react';
import { Link } from "gatsby"
import "./portfolio.scss";
import Layout from '../layout/layout'
import { graphql } from 'gatsby'
import Seo from '../components/seo/index'

class Portfolio extends Component {

  constructor(props) {
    super();
    this.state = {
      listItem: 'showcase',
      items: props.data.allWordpressCaseStudies.edges
    }

    // testing
    this.handleTest = this.handleTest.bind(this);
  }

  //Handles the change from filter items
  handleTest(text) {
    this.setState({
      listItem: text
    });
  }

  render() {
    console.log(this.state.items)
    return (
      <Layout>
        <Seo postTitle="Portfolio Page" />
        <div className="title">
          <h1>Portfolio Main</h1>
          <h2>Showcasing a selection of projects</h2>
        </div>


        {/* Filter Items List*/}
        {/* must add conditonal render so that it stays displayed when clicked */}
        <div className="FilterItems">
          <div>
            {['showcase', 'all', 'react', 'web', 'UX/UI', 'React Native', 'Gatsby'].map((item) => (

              <div
                className={this.state.listItem === item ? 'ActiveItems' : 'nonActive'}
                role="button"
                tabIndex={0}
                onKeyDown={() => this.handleTest(item)}
                onClick={() => this.handleTest(item)}>
                {item}
              </div>
            ))}
          </div>
        </div>

        <div className="list">

          {/*TODO: to build the list of items to display */}
          {this.state.items.filter((box) => {

            if (this.state.listItem === 'all') {
              return true;
            }
            // how to refactor this so it easier to read
            else if (this.state.listItem === 'showcase') {
              return box.node.case_study.options.showcase
            }
            else if (this.state.listItem === 'web') {
              return box.node.case_study.options.web
            }
            else if (this.state.listItem === 'react') {
              return box.node.case_study.options.react
            }
            else if (this.state.listItem === 'UX/UI') {
              return box.node.case_study.options.uxUi
            }
            else if (this.state.listItem === 'React Native') {
              return box.node.case_study.options.reactNative
            }
            else if (this.state.listItem === 'Gatsby') {
              return box.node.case_study.options.gatsby
            }
            //   return false
            // }
            return null
          }
          ).map((item) => (
            <ShowcaseItems
              imageLocation={item.node.case_study.image}
              video={item.node.case_study.video}
              text={item.node.title}
              tech={item.node.case_study.techUsed}
              casestudy={item.node.slug}
            />
          )
          )}

        </div>



      </Layout>
    )
  }
}

export default Portfolio



function ShowcaseItems(props) {
  // debugging tools
  console.log("this is showcaseitems")
  console.log(props.video)

  var imageSrc;
  // checks if there is an image if not defaults to placeholder
  if (props.imageLocation != null) {


    // checks if a gif file
    if (props.imageLocation.localFile != null || props.imageLocation.localFile.childImageSharp != null) {
      //  not a gif file
      imageSrc = props.imageLocation.localFile.childImageSharp.fluid.srcSet
    }


  }

  return (
    <div className="list--item">
      
      {props.video ? <video loop autoPlay muted>
        <source src={props.video.localFile.url} type="video/mp4"/>
            Your browser does not support the video tag.
          </video>:<img alt="" srcSet={imageSrc} />
}
          <div className="list--item--text">
            <div className="list--item--header">{props.text}</div>
            <div>Tech Used: {props.tech}</div>
            {props.casestudy ? <Link className="button button--code" to={"/" + props.casestudy}>Learn More</Link> : ''}
          </div>
        </div>
        )
}

        export const pageQuery = graphql`
        {
          allWordpressCaseStudies {
          edges {
          node {
          title
        slug
        case_study {
          demoLink
          githubLinks
        techUsed
        uiuxLink
        image {
          localFile {
          childImageSharp {
          fluid {
          srcSet
        }
              }
        ext
        publicURL
            }
          }
        options {
          gatsby
            react
        reactNative
        showcase
        uxUi
        web
          }
        video {
          localFile {
          url
        }
          }
        }
      }
    }
  }
}


        `